<template>
  <div>
    <router-view/>
  </div>
</template>

<style>
  @import './assets/styles/buttons_style.css';
  @import './assets/styles/forms_style.css';
  @import './assets/styles/typography_style.css';
  @import './assets/styles/stylesheet.css';
</style>

<script>

export default {
  name: 'App'
};
</script>