import axios from 'axios';
import store from '@/models/store/store';

const shuffledColors = [
  'rgb(54, 140, 225)',
  'rgb(48, 239, 130)',
  'rgb(198, 214, 60)',
  'rgb(255, 94, 99)',
  'rgb(150, 61, 179)',
  'rgb(35, 171, 216)',
  'rgb(82, 246, 103)',
  'rgb(226, 183, 47)',
  'rgb(254, 75, 131)',
  'rgb(110, 64, 170)',
  'rgb(96, 84, 200)',
  'rgb(26, 199, 194)',
  'rgb(127, 246, 88)',
  'rgb(251, 150, 51)',
  'rgb(228, 65, 157)',
  'rgb(76, 110, 219)',
  'rgb(29, 223, 163)',
  'rgb(175, 240, 91)',
  'rgb(255, 120, 71)',
  'rgb(191, 60, 175)'
];

export default {

  isCancel: axios.isCancel,

  deepCopy: function (original) {
    if (Array.isArray(original)) {
      return original.map(elem => this.deepCopy(elem));
    } else if (typeof original === 'object' && original !== null) {
      return Object.fromEntries(
        Object.entries(original)
          .map(([k, v]) => [k, this.deepCopy(v)]));
    } else {
      // Primitive value: atomic, no need to copy
      return original;
    }
  },

  objectHasKeys: function (obj, keys) {
    return keys.every(key => Object.keys(obj).includes(key));
  },

  isEmptyObject: function (obj) {
    for (const property in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, property)) {
        return false;
      }
    }
    return true;
  },

  parseQueryParameters: function (url) {
    const queryString = url.match('(?=[?]).+')[0];
    const queryObj = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      queryObj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return queryObj;
  },

  assignColorFromIndex: function (n) {
    return shuffledColors[n % shuffledColors.length];
  },

  getClinicNameFromClinicGuid: function (clinicGuid) {
    const clinicList = store.getters.allClinics;
    for (let item of clinicList) {
      if (item.clinicId == clinicGuid) {
        return item.clinicName;
      }
    }
    return undefined;
  },

  deterministicStringify: function (obj) {
    let type = Object.prototype.toString.call(obj);
  
    if (type === '[object Object]') {
      let pairs = [];
      Object.keys(obj).forEach(k => {
        if (Object.prototype.hasOwnProperty.call(obj, k)) {
          pairs.push([k, this.deterministicStringify(obj[k])]);
        }
      }, this);
      pairs.sort(function(a, b) { return a[0] < b[0] ? -1 : 1; });
      pairs = Array.prototype.map.call(pairs, function(v) { return '"' + v[0] + '":' + v[1]; });
      return '{' + pairs + '}';
    }
  
    if (type === '[object Array]') {
      return '[' + obj.map(v => { return this.deterministicStringify(v); }, this) + ']';
    }
  
    return JSON.stringify(obj);
  },

  cyrb64Hash: function(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    return (h2>>>0).toString(16).padStart(8,0)+(h1>>>0).toString(16).padStart(8,0);
  },

  // Generates a unique ID, not collision proof but fine for the purposes of
  // fingerprinting a component
  generateUniqueID: function () {
    return Date.now().toString(36) + Math.random().toString(36).slice(2);
  },

  smyEncryptDecrypt: function (message, key) {
    let output = '';
    let j = 0;

    for (let i = 0; i < message.length; i++) {
      output += String.fromCharCode(message.charCodeAt(i) ^ key.charCodeAt(j));
      j++;
      if (j >= key.length) {
        j = 0;
      }
    }

    return output;
  },

  // deepCompare function adapted from answers found here: https://stackoverflow.com/questions/29532094/recursive-deep-compare
  deepCompare: function (obj1, obj2) {
    // Test if the objects are *actually* both references to the same object
    if (obj1 === obj2) {
      return true;
    }

    // Test if the objects are different types (structurally different, like inheriting from different base classes)
    if (typeof obj1 !== typeof obj2) {
      return false;
    }

    // Test if obj1 is an actual 'Object' primitive (so we know that the required functions are supported)
    if (Object(obj1) !== obj1) {
      return false;
    }

    // Get the keys in Obj1
    const keys = Object.keys(obj1);

    // Test if both objects have the same number of keys
    if (keys.length !== Object.keys(obj2).length) {
      return false;
    }

    // Iterate over keys
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // Test if Obj2 has the same key
      if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
        return false;
      }

      // Recurse to allow for the key to contain an object itself
      if (!this.deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

};