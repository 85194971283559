import generalFunctions from '@/models/common/general-functions';
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      showingError: false,
      blockUI: false,
      message: undefined,
      displayTime: undefined
    };
  },
  computed: {
    ...mapGetters({
      latestError: 'latestError'
    })
  },
  methods: {
    checkForError: function () {
      // Check for dependencies
      const requiredKeys = ['user', 'timestamp', 'alertUser', 'displayForSeconds', 'blockUI'];
      if (generalFunctions.objectHasKeys(this.latestError, requiredKeys)) {
        // All requirements are met.
        // Check if the error is intended to be displayed
        if (this.latestError.alertUser) {
          // Check if we should still be showing the error
          const finishedDate = new Date(new Date(this.latestError.timestamp).getTime() + (this.latestError.displayForSeconds * 1000));
          if (new Date() < finishedDate) {
            // The error should be shown
            this.message = this.latestError.user;
            this.displayTime = this.latestError.displayForSeconds * 1000;
            this.blockUI = this.latestError.blockUI;
            this.showingError = true;
          }
        }
      }
    }
  },
  watch: {
    latestError: function (newVal, oldVal) {
      this.checkForError();
    }
  },
  mounted () {
  }
};