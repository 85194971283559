var cache = {};

function deleteFromCache(requestHash) {
  if (requestHash in cache) {
    delete cache[requestHash];
  }
}

export default {

  checkCache: function (requestHash) {

    if (requestHash in cache) {

      if (cache[requestHash].expiresOn > Date.now()) {
        return cache[requestHash].request;
      } else {
        deleteFromCache(requestHash);
      }

    }
    
    return false;  
  },

  storePromise: function (requestHash, request, cacheSecondsTTL) {
    cache[requestHash] = {};
    cache[requestHash].request = request;
    cache[requestHash].expiresOn = Date.now() + (cacheSecondsTTL * 1000);
  },

  getCacheEntries: function () {
    return cache;
  },

  resetCache: function () {
    cache = {};
  }

};