const msalConfig = {
  endpoint: 'https://riwitnessqc.b2clogin.com/riwitnessqc.onmicrosoft.com/B2C_1_SignIn/oauth2/v2.0/',
  params: {
    response_type: 'id_token',
    scope: 'openid',
    client_id: 'f5b76b28-d196-413b-a47f-1fbecccf4b67',
    redirect_uri: window.location.origin + '/login',
    state: undefined,
    nonce: undefined,
    response_mode: 'fragment'
  },
  refreshParams: {
    response_type: 'id_token',
    scope: 'openid',
    client_id: 'f5b76b28-d196-413b-a47f-1fbecccf4b67',
    redirect_uri: window.location.origin + '/login',
    state: undefined,
    nonce: '12345',
    prompt: 'none',
    response_mode: 'fragment'
  },
  passwordResetParams: {
    p: 'B2C_1_password_reset',
    response_type: 'id_token',
    scope: 'openid',
    client_id: 'f5b76b28-d196-413b-a47f-1fbecccf4b67',
    redirect_uri: window.location.origin + '/login',
    state: undefined,
    nonce: undefined,
    prompt: 'login'
  }
};

export default {
  getAuthorizeUrl: function () {
    return this.getUrlFromMsalConfig(msalConfig.params);
  },
  getRefreshIdTokenUrl: function () {
    return this.getUrlFromMsalConfig(msalConfig.refreshParams);
  },
  redirectUserToLogIn: function () {
    let url = this.getAuthorizeUrl();
    window.location.replace(url);
  },
  redirectUserToLogOut: function () {
    let url = msalConfig.endpoint + 'logout?redirect_uri=' + window.location.origin + '/';
    window.location.replace(url);
  },
  redirectUserToChangePassword: function () {
    let url = this.getUrlFromMsalConfig(msalConfig.passwordResetParams);
    window.location.replace(url);
  },
  getUrlFromMsalConfig: function (params) {
    const esc = encodeURIComponent;
    const url = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
    return msalConfig.endpoint + 'authorize?' + url;
  }
};