import apiEndpoints from '@/models/common/api-endpoints';
import jwt from 'jsonwebtoken';
import homeViewComponentModel from '@/component-models/home-view-component-model';

const magicClinic = '00000000-0000-0000-0000-000000000000';
const PermissionIDSysAdmin = 'SysAdmin';
const PermissionIDGlobalAdmin = 'GlobalAdmin';

export default {

  state: {
    idToken: undefined,
    allDeviceInfo: undefined,
    currentClinic: undefined,
    rawClinicAndPermissionsInfo: undefined,
    emailAddress: undefined,
    witnessInstallList: {
      loading: true,
      error: false,
      ready: false,
      list: undefined,
      currentSelected: undefined
    },
    idTokenRefreshTimer: undefined
  },

  getters: {
    idToken: function (state) {
      return state.idToken;
    },
    isAdminOfCurrentClinic: function (state) {
      if (state?.currentClinic?.isAdmin) {
        return true;
      }
      return false;
    },
    isAdminOfAnyClinic: function (state) {
      if (state.rawClinicAndPermissionsInfo) {
        for (let clinic of state?.rawClinicAndPermissionsInfo) {
          if (clinic?.isAdmin) {
            return true;
          }
        }
      }
      return false;
    },
    isSysAdmin: function (state) {
      if (state.rawClinicAndPermissionsInfo) {
        for (let clinic of state?.rawClinicAndPermissionsInfo) {
          if (clinic.clinicId === magicClinic) {
            for (let permission of clinic.permissions) {
              if (permission === PermissionIDSysAdmin) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    isGlobalAdmin: function (state) {
      if (state.rawClinicAndPermissionsInfo) {
        for (let clinic of state?.rawClinicAndPermissionsInfo) {
          if (clinic.clinicId === magicClinic) {
            for (let permission of clinic.permissions) {
              if (permission === PermissionIDGlobalAdmin) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    witnessInstallList: function (state) {
      return state.witnessInstallList;
    },
    currentClinic: function (state) {
      return state.currentClinic;
    },
    allDeviceInfo: function (state) {
      return state.allDeviceInfo;
    },
    witnessDBs: function (state) {
      return state.witnessInstallList.list;
    },
    emailAddress: function (state) {
      return state.emailAddress;
    },
    allClinics: function (state) {
      return state.rawClinicAndPermissionsInfo?.filter((item) => {
        return !(item.clinicId === magicClinic);
      });
    },
    adminClinics: function (state, getters) {
      return getters.allClinics.filter((item) => {
        return (item.isAdmin);
      });
    }
  },

  mutations: {
    startIdTokenRefreshTimer: function (state, duration = 55 * 60 * 1000) {
      state.idTokenRefreshTimer = setInterval(() => {
        homeViewComponentModel.methods.refreshIdToken();
      }, duration);
    },
    updateIdToken: function (state, newIdToken) {
      let decodedToken = jwt.decode(newIdToken);
      if (decodedToken?.emails !== undefined && decodedToken.emails[0]?.length > 0)
      {
        state.emailAddress = decodedToken.emails[0];
      }
      state.idToken = newIdToken;
    },
    setCurrentClinic: function (state, payload) {
      state.currentClinic = payload;
    },
    setRawClinicAndPermissionsInfo: function (state, payload) {
      state.rawClinicAndPermissionsInfo = payload;
    },
    setAllDeviceInfo: function (state, payload) {

      state.allDeviceInfo = payload;

      const witnessDevices = [];
      for (let witnessDevice of payload) {
        if (witnessDevice.type === 'WitnessDB') {
          witnessDevices.push({
            deviceGUID: witnessDevice.deviceId,
            deviceName: witnessDevice.name
          });
        }
      }

      if (witnessDevices.length > 0) {
        state.witnessInstallList.list = witnessDevices;
        state.witnessInstallList.currentSelected = witnessDevices[0];
        state.witnessInstallList.loading = false;
        state.witnessInstallList.error = false;
        state.witnessInstallList.ready = true;
      } else {
        state.witnessInstallList.list = undefined;
        state.witnessInstallList.currentSelected = undefined;
        state.witnessInstallList.loading = false;
        state.witnessInstallList.error = true;
        state.witnessInstallList.ready = true;
      }
    }
  },

  actions: {
    fetchAndStoreClinicAndPermissionsInfo: async function (context) {
      const response = await apiEndpoints.getPermissionsData();
      
      if (response.result) {
        context.commit('setRawClinicAndPermissionsInfo', response.data);

        if (response.data && response.data.length > 0) {
          // Check if the currentClinic is still in allClinics
          let currentClinicIsInAllClinics = false;
          if (context.getters.currentClinic){
            for (let clinic of context.getters.allClinics) {
              if (clinic.clinicId === context.getters.currentClinic.clinicId) {
                currentClinicIsInAllClinics = true;
              }
            }
          }

          // If the currentClinic was not in the list, or there was no currentClinic, set the first one in allClinics
          if (!currentClinicIsInAllClinics) {
            context.commit('setCurrentClinic', context.getters.allClinics[0]);
          }
        }
      }
      
    },
    fetchAndStoreAllDeviceInfo: async function (context) {
      const response = await apiEndpoints.getDeviceInfoForCurrentClinic();
      
      if (response.result) {
        context.commit('setAllDeviceInfo', response.data);
      }
      else {
        // NOTE: Slightly different call required to dispatch to an action in
        //       another Vuex Module 
        this.dispatch('registerError', response.error, {root: true});
      }
    },
    fetchAndStoreClinicAndDeviceInfo: async function (context) {
      context.dispatch('fetchAndStoreClinicAndPermissionsInfo').then((res) => {
        context.dispatch('fetchAndStoreAllDeviceInfo');
      });
    }

  }
};