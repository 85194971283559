export default {
  state: {
    dateFilter: {
      jumpAmount: undefined,
      jumpPeriod: undefined,
      startDate: undefined,
      endDate: undefined,
      dateRangeTitle: undefined
    }
  },

  getters: {
    dateFilter: function (state) {
      return state.dateFilter;
    }
  },

  mutations: {
    updateDateFilter: function (state, { jumpAmount, jumpPeriod, startDate, endDate, dateRangeTitle }) {
      state.dateFilter = {
        jumpAmount: jumpAmount,
        jumpPeriod: jumpPeriod,
        startDate: startDate,
        endDate: endDate,
        dateRangeTitle: dateRangeTitle
      };
    }
  }
};