import apiConnector from '@/models/common/api-connector';
import generalFunctions from '@/models/common/general-functions';
import store from '@/models/store/store';

const riwiqEndpoints = {
  permissions: '/permissions',
  permissionsForClinic: '/Permissions/Clinic/<clinicId>', 
  managePermission: '/Permissions/Clinic/<clinicId>/user/<userId>/<permissionName>',
  clinic: '/clinics/<clinicId>',
  clinics: '/Clinics', 
  clinicConnectionNotifications: '/clinics/<clinicId>/connectionnotificationusers/<userId>',
  connectors: '/clinics/<clinicId>/connectors',
  device: '/clinics/<clinicId>/devices/<deviceId>',
  devices: '/clinics/<clinicId>/devices',
  inviteUser: '/Permissions/Clinic/<clinicId>/inviteuser/<permissionName>',
  deviceConfig: '/clinics/<clinicId>/devices/<deviceId>/config',
  deviceErrors: '/clinics/<clinicId>/devices/<deviceId>/errors',
  witnessDashboardsReports: '/dashboardmailconfigs',
  witnessDashboardsReport: '/dashboardmailconfigs/<dashboardId>',
  witnessIntervalConfig: '/clinics/<clinicId>/intervalconfigs/<intervalId>',
  witnessIntervalConfigs: '/clinics/<clinicId>/intervalconfigs',
  requestConnectorCode: '/clinics/<clinicId>/connectors/cliniccode',
  // New API endpoints
  allClinics: '/AllClinics',
  getAllWitnessPointNames: '/AllWitnessPointNames',
  allLocationsAndOperators: '/AllLocationsAndOperators',
  allWitnessEvents: '/AllWitnessEvents',
  witnessIntervalSummary: '/clinics/<clinicId>/witnessintervals/<intervalId>/Summary',
  witnessIntervalDataFromId: '/clinics/<clinicId>/witnessintervals/<intervalId>',
  witnessIntervalDataFromConfig: '/clinics/<clinicId>/witnessintervals',
  allWitnessEventsSummary: '/AllWitnessEvents/Summary',
  witnessDashboardConfigs: '/DashboardConfigs',
  witnessDashboardConfig: '/DashboardConfigs/<dashboardId>',
  availableDatabases: '/databaseConnections'
};

const apiURLInfo = {
  '401a220ff9efbbf7': {
    'salt': '4nj5ml8gaf8zijb9qro',
    'value': 'O\u0015@K\b\u0016\tC\r\u0016\u0016GC\u000b\u001a\u001eC^AW\u0001G@\b\u0014\u0003\u0012^\u001ePODFRB\\\u0006GZ\u0015\u0000\u0011LYUE\u0017\u001d\u0016TZW\nQP\u0015\n\u0010@\r\u0012\u001ev]]Y\\Z\'[]\u000f\u0000\u0001\u0016XBbPCB^V\\7QG\u0014\u00154S\u0019\u0000\u001f\u0003\u0002\u001aZFPFI'
  },
  '88d1741c30a9e061': {
    'salt': 'lx48gczpo7ql9y5nba8',
    'value': 'C\u001a\u0010F\u000eA[\u0017]FDEF\u000b\u0017\u0019OQ\u0011Z\u0007\u0010\u0012\\DFUFAPH_\u0016Y\u001fA\u0010\u0006\u0016PWAYAPB\u0016X]LG\u0018@\u0000\u000e[[WSAZC\u001a\f\u001a\u0017&X\u000b\r\bVv]^[PRLYJk\u0000F\u0014\n\u0002PfWD@Eg\b\u0018\u000f\u00151q17OXF[\u0012H'
  },
  'cf416d75fe8bac02': {
    'salt': 'qlz4ra8y9n85bbj1aa9',
    'value': 'L\u0016FG\t\u0016_GYG\u0010\u0013E\b\u001aI@]G[\u0000G\u0016\f@R\u0014\n\u0018SO\u0013EQDP\u0007G\f\u0011T@J\rSF\u0017J\u0015W\\[\u000bQ\u0006\u0011^AFY\u0014\u001dv\n^ZZV&[\u000b\u000bTP\u0010\fDaP\u0014A]PP6Q\u0011\u0010AeUM\u0006\u001c\u0003U\u0019Y@\\GI'
  },
  '8663ffb69dde3025': {
    'salt': 'd8npwpa4xl0hz8qlhs',
    'value': 'B\u0013L\u0017\rC\u0003\u0010ZD\u0015GK_N\u0019NXM\u000b\u0004\u0012J[CD\u0004DL\u0004\u0011_\u0017PC\u0010\u0013\u0004NWPC\bC]\u0016OX\\E\u001bIC\u0002V\\\\U\u0002CW\u0017C\f\u001b\u001ez\t\b\u000fPQq_\u000fY]\u0006\u0015YKb\\\u0017\u0017\bZWaU\u0015BH3Q\u0018\u000e\u001cm 25\u0017_AYCJ'
  },
  '0324d5822e3479ad': {
    'salt': 'hly88nqagnvd64zv0soc',
    'value': 'C\u0017\u0017C\nA[\u0014_\u0017\u0015\u0016\u0011\n\u0017\u001cO\\\u0016_\u0003\u0010\u0012_F\u0017\u0004\u0015\u0016QHZ\u0016T\u0018D\u0014\u0006\u0016SU\u0010\b\u0012\u0007C\u0016]]A@\u001dD\u0000\u000eXY\u0006\u0002\u0012\rB\u001a\t\u001a\u001a!]\u000f\r\bUt\f\u000f\b\u0007SL\\Jf\u0007C\u0010\n\u0002Sd\u0006\u0015\u0013\u0012f\b\u001d\u000f\u00186t57O[D\nC\u001b'
  }
};

function getApiDetails (apiURLInfo) {
  const hash = generalFunctions.cyrb64Hash(window.location.host);
  if( hash in apiURLInfo) {
    let key = generalFunctions.cyrb64Hash(window.location.host + apiURLInfo[hash].salt);
    return JSON.parse(generalFunctions.smyEncryptDecrypt(apiURLInfo[hash].value, key));
  }

  return {url: undefined};
};

const apiDetails = getApiDetails(apiURLInfo);

export default {

  apiDetails: apiDetails,
  
  getPermissionsData: async function () {
      
    const response = await apiConnector.requestEx({
      requestName: 'getPermissions',
      url: apiDetails.url + riwiqEndpoints.permissions,
      userErrorMessage: 'Fetching permissions for one or more clinics failed. Please refresh the page and try again.'
    });

    return response;
  },
  
  getDeviceInfoForCurrentClinic: async function () {
      
    // NOTE: Specific case where if getPermissionData fails it doesn't initialise current clinic
    //       and then this is still auto-called and would fall over
    if (!store.getters.currentClinic) {
      const errorObj = {
        error: 'No currentClinic?! Dependencies for getDeviceInfoForCurrentClinic() not fulfilled',
        user: 'An unrecoverable error has occurred (no clinics found for user). Please refresh the page and try again.',
        blockUI: true,
        displayForSeconds: 9999,
        alertUser: true
      };
      return { result: false, error: errorObj };
    }
    
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getDeviceInfoForCurrentClinic',
      url: apiDetails.url + riwiqEndpoints.devices.replace('<clinicId>', store.getters.currentClinic.clinicId),
      userErrorMessage: 'Fetching device info for current clinic failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },
    
  getWitnessDashboardsReportsSubscriptionStatus: async function () {
    
    const fullStatus = await apiConnector.requestEx({
      requestName:'getWitnessDashboardsReportsSubscriptionStatus',
      url: apiDetails.url + riwiqEndpoints.witnessDashboardsReports,
      userErrorMessage:'Fetching the report subscription status for this dashboard failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  postWitnessDashboardsReportSubscription: async function (body) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName:'postWitnessDashboardsReportSubscription',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.witnessDashboardsReports,
      userErrorMessage:'Subscribing to reports for this dashboard failed. Please refresh the page and try again.',
      data: body
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },
  
  deleteWitnessDashboardReportSubscription: async function (dashboardId) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName:'deleteWitnessDashboardReportSubscription',
      method: 'delete',
      url: apiDetails.url + riwiqEndpoints.witnessDashboardsReport.replace('<dashboardId>', dashboardId),
      userErrorMessage:'Unsubscribing to reports for this dashboard failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  generatePDF: async function (baseUrl, configPatch) {
      
    const response = await apiConnector.requestEx({
      requestName: baseUrl,
      url: baseUrl,
      userErrorMessage:'Retrieving the PDF failed. Please refresh the page and try again.',
      useCache: true,
      configPatch: configPatch
    });

    return response;
  },
  
  putWitnessIntervalConfig: async function (clinicId, deviceId, intervalId, data) {

    const fullStatus = await apiConnector.requestEx({
      requestName: 'putWitnessIntervalConfig' + deviceId + intervalId,
      method: 'put',
      url: apiDetails.url + riwiqEndpoints.witnessIntervalConfig.replace('<clinicId>', clinicId).replace('<intervalId>', intervalId),
      userErrorMessage: 'Updating the interval configuration failed. Please refresh the page and try again.',
      data: data
    });
    
    let status = this.transformStatus(fullStatus);

    if (!fullStatus.result) {
      if (fullStatus.response.error.response.status == 409) {
        // Special handling for 409 status which is possible outcome. Update error message
        status.error.user = 'The name is already in use. Please change and try again.';
      }
    }

    return status;
  },

  postWitnessIntervalConfig: async function (clinicId, deviceId, data) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'postWitnessIntervalConfig' + deviceId,
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.witnessIntervalConfigs.replace('<clinicId>', clinicId),
      userErrorMessage: 'Saving the interval configuration failed. Please try again.',
      data: data
    });
    
    let status = this.transformStatus(fullStatus);
    
    if (!fullStatus.result) {
      if (fullStatus.response.error.response.status == 409) {
        // Special handling for 409 status which is possible outcome. Update error message
        status.error.userMessage = 'The interval name provided is already in use. Please enter a different name and try again.';
      }
    }

    return status;
  },

  deleteWitnessIntervalConfig: async function (clinicId, deviceId, intervalId) {
    
    const fullStatus = await apiConnector.requestEx({
      requestName: 'deleteWitnessIntervalConfig' + deviceId + intervalId,
      method: 'delete',
      url: apiDetails.url + riwiqEndpoints.witnessIntervalConfig.replace('<clinicId>', clinicId).replace('<intervalId>', intervalId),
      userErrorMessage: 'Deleting the interval configuration failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);
    
    return status;
  },

  getWitnessPointNames: async function (clinicId, deviceId) {

    const fullStatus = await apiConnector.requestEx({
      requestName: 'getWitnessPointNames',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.getAllWitnessPointNames,
      userErrorMessage: 'Fetching the names of the witness points available in this witness database failed. Please refresh the page and try again.',
      data: {
        clinics: [ clinicId ]
      }
    });

    let status = this.transformStatus(fullStatus);
    
    return status;
  },

  getWitnessIntervalConfigsForClinic: async function (clinicId, deviceId) {

    const fullStatus = await apiConnector.requestEx({
      requestName: 'getWitnessIntervalConfigsForClinic',
      url: apiDetails.url + riwiqEndpoints.witnessIntervalConfigs.replace('<clinicId>', clinicId),
      userErrorMessage: 'Fetching the configured intervals for this clinic failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);
    
    return status;
  },

  getClinicsForCurrentAPI: async function () {
  
    const response = await apiConnector.requestEx({
      requestName: 'getClinics',
      url: apiDetails.url + riwiqEndpoints.clinics,
      userErrorMessage: 'Fetching clinic list failed. Please refresh the page and try again.'
    });
      
    return response;
  },

  getPermissionsForClinic: async function (clinicGUID) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getPermissionsForCurrentClinic',
      url: apiDetails.url + riwiqEndpoints.permissionsForClinic.replace('<clinicId>', clinicGUID),
      userErrorMessage: 'Fetching users for clinic failed. This error has been logged. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },

  addPermissionForClinic: async function (userGUID, permissionName, clinicGUID) {
    
    const fullStatus = await apiConnector.requestEx({
      requestName: 'addPermissionForCurrentClinic' + store.getters.currentClinic.clinicId + userGUID + permissionName,
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.managePermission.replace('<clinicId>', clinicGUID).replace('<userId>', userGUID).replace('<permissionName>', permissionName),
      userErrorMessage: 'Adding one or more permissions for this user failed. Please try again.'
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },

  removePermissionForClinic: async function (userGUID, permissionName, clinicGUID) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'removePermissionForCurrentClinic' + store.getters.currentClinic.clinicId + userGUID + permissionName,
      method: 'delete',
      url: apiDetails.url + riwiqEndpoints.managePermission.replace('<clinicId>', clinicGUID).replace('<userId>', userGUID).replace('<permissionName>', permissionName),
      userErrorMessage: 'Removing one or more permissions for this user failed. Please try again.'
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },

  inviteUserForClinic: async function (emailAddress, initialPermission, clinicGUID) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'sendNewUserInvitation' + store.getters.currentClinic.clinicId + emailAddress,
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.inviteUser.replace('<clinicId>', clinicGUID).replace('<permissionName>', initialPermission),
      userErrorMessage: 'Inviting user failed. Please try again.',
      params: {emailaddress: emailAddress}
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },

  createClinic: async function (clinicName, databaseConnectionId) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'createClinic' + clinicName,
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.clinics,
      userErrorMessage: 'Clinic creation failed. Please refresh the page and try again.',
      data: { name: clinicName, databaseconnectionid: databaseConnectionId }
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },
    
  getDevicesForCurrentClinic: async function () {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getDevicesForCurrentClinic',
      url: apiDetails.url + riwiqEndpoints.devices.replace('<clinicId>', store.getters.currentClinic.clinicId),
      userErrorMessage: 'Fetching devices for the clinic failed. Please refresh the page and try again.'
    });
      
    let status = this.transformStatus(fullStatus);

    return status;
  },
    
  getDeviceErrors: async function (deviceId) {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getDeviceErrors',
      url: apiDetails.url + riwiqEndpoints.deviceErrors.replace('<clinicId>', store.getters.currentClinic.clinicId).replace('<deviceId>', deviceId), 
      userErrorMessage: 'Fetching errors for the device failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  renameClinicForCurrentAPI: async function (clinicId, clinicName) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'renameClinic' + clinicId,
      method: 'patch',
      url: apiDetails.url + riwiqEndpoints.clinic.replace('<clinicId>', clinicId),
      userErrorMessage: 'Failed to rename clinic. Please refresh the page and try again.',
      data: { Name: clinicName }
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },
    
  createDevice: async function (deviceName, deviceType, clinicId) {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'createDevice',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.devices.replace('<clinicId>', clinicId), 
      userErrorMessage: 'Creating a device for the clinic failed. Please refresh the page and try again.',
      data: { Name: deviceName, Type: deviceType }
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },
    
  getDeviceConfig: async function (deviceId, clinicId) {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getDeviceConfig',
      url: apiDetails.url + riwiqEndpoints.deviceConfig.replace('<clinicId>', clinicId).replace('<deviceId>', deviceId),
      userErrorMessage: 'Retrieving the device configuration failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },
    
  renameDevice: async function (deviceId, deviceName, clinicId) {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'renameDevice',
      method: 'patch',
      url: apiDetails.url + riwiqEndpoints.device.replace('<clinicId>', clinicId).replace('<deviceId>', deviceId),
      userErrorMessage: 'Renaming the device failed. Please refresh the page and try again.',
      data: { Name: deviceName }
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },

  deleteClinicForCurrentAPI: async function (clinicId) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'deleteClinic' + clinicId,
      method: 'delete',
      url: apiDetails.url + riwiqEndpoints.clinic.replace('<clinicId>', clinicId), 
      userErrorMessage: 'Failed to delete the clinic. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },
    
  deleteDevice: async function (deviceId, clinicId) {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'deleteDevice',
      method: 'delete',
      url: apiDetails.url + riwiqEndpoints.device.replace('<clinicId>', clinicId).replace('<deviceId>', deviceId),
      userErrorMessage: 'Deleting the device failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },
    
  updateDeviceConfig: async function (deviceId, deviceConfig, clinicId) {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'updateDeviceConfig',
      method: 'patch',
      url: apiDetails.url + riwiqEndpoints.deviceConfig.replace('<clinicId>', clinicId).replace('<deviceId>', deviceId),
      userErrorMessage: 'Updating the device configuration failed. Please refresh the page and try again.',
      data: deviceConfig
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },

  getConnectorsForCurrentClinic: async function () {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getConnectorsForCurrentClinic',
      url: apiDetails.url + riwiqEndpoints.connectors.replace('<clinicId>', store.getters.currentClinic.clinicId),
      userErrorMessage: 'Fetching connectors for the clinic failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);
      
    return status;
  },
    
  getDeviceAlertSubscriptionState: async function (clinicId, userGUID) {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'subscribeToDeviceAlerts',
      url: apiDetails.url + riwiqEndpoints.clinicConnectionNotifications.replace('<clinicId>', clinicId).replace('<userId>', userGUID),
      userErrorMessage: 'Getting the subscription to notifications status for this clinic failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  updateDeviceAlertSubscription: async function (clinicId, userGUID, subscriptionState) {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'subscribeToDeviceAlerts',
      method: 'put',
      url: apiDetails.url + riwiqEndpoints.clinicConnectionNotifications.replace('<clinicId>', clinicId).replace('<userId>', userGUID),
      userErrorMessage: 'Updating subscription to notifications for this clinic failed. Please refresh the page and try again.',
      data: subscriptionState
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  getNewConnectorCodeForCurrentClinic: async function () {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getNewConnectorCodeForCurrentClinic',
      url: apiDetails.url + riwiqEndpoints.requestConnectorCode.replace('<clinicId>', store.getters.currentClinic.clinicId),
      userErrorMessage: 'Fetching a new connector code for the clinic failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  getAllClinics: async function () {
    
    const response = await apiConnector.requestEx({
      requestName: 'allClinics',
      url: apiDetails.url + riwiqEndpoints.allClinics,
      userErrorMessage: 'Fetching all clinics failed. Please try again.'
    });

    return response;
  },
  
  getAllWitnessPointNames: async function (clinicIds) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'allWitnessPointNames',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.getAllWitnessPointNames,
      userErrorMessage: 'Fetching all witness point names failed. Please try again.',
      data: {
        clinics: clinicIds
      }
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },
  
  getAllLocationsAndOperators: async function (clinicIds) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'allLocationsAndOperators',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.allLocationsAndOperators,
      userErrorMessage: 'Fetching all locations and operators failed. Please try again.',
      data: {
        clinics: clinicIds
      }
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },
  getAllWitnessEvents: async function (clinicIds, nonProcedureEvents, events, locations, operators, startDate, endDate, groupby = null, maxItems = null, untilDateTime = null, cancelKey = undefined) {
    // getAllWitnessEvents: async function ({
    //   clinicIds,
    //   nonProcedureEvents,
    //   events,
    //   locations,
    //   operators,
    //   startDate,
    //   endDate,
    //   groupby = null,
    //   maxItems = null,
    //   untilDateTime = null,
    //   cancelKey = undefined
    // } = {}) {
    
    let maxItemsStringForUrl = '';
    if (maxItems) {
      maxItemsStringForUrl = '?maxitems=' + maxItems;
    }
    let untilDateTimeStringForUrl = '';
    if (untilDateTime) {
      untilDateTimeStringForUrl = '&untildatetime=' + untilDateTime;
    }

    const fullStatus = await apiConnector.requestEx({
      requestName: 'allWitnessEvents',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.allWitnessEvents + maxItemsStringForUrl + untilDateTimeStringForUrl,
      userErrorMessage: 'Fetching all witness events failed. Please try again.',
      data: {
        clinics: clinicIds,
        witnesspoints: events,
        operators: operators,
        locations: locations,
        startdate: startDate,
        endDate: endDate,
        IncludeAdminAssigns: nonProcedureEvents.includeAssigns,
        IncludeDiscards: nonProcedureEvents.includeDiscards,
        IncludeMismatches: nonProcedureEvents.includeMismatches,
        // Optionals
        groupby: groupby
      },
      cancelKey: cancelKey
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  getAllWitnessEventsSummary: async function (clinicIds, nonProcedureEvents, events, locations, operators, startDate, endDate, groupby = null, cancelKey = undefined) {

    const response = await apiConnector.requestEx({
      requestName: 'allWitnessEventsSummary',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.allWitnessEventsSummary,
      userErrorMessage: 'Fetching all witness events summary failed. Please try again.',
      data: {
        clinics: clinicIds,
        witnesspoints: events,
        operators: operators,
        locations: locations,
        startdate: startDate,
        endDate: endDate,
        IncludeAdminAssigns: nonProcedureEvents.includeAssigns,
        IncludeDiscards: nonProcedureEvents.includeDiscards,
        IncludeMismatches: nonProcedureEvents.includeMismatches,
        // Optionals
        groupby: groupby
      },
      cancelKey: cancelKey
    });

    return response;
  },

  getWitnessIntervalSummary: async function (clinicId, intervalId, startDate, endDate, groupby = null, cancelKey = undefined) {

    const fullStatus = await apiConnector.requestEx({
      requestName: 'getWitnessIntervalSummary',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.witnessIntervalSummary.replace('<clinicId>', clinicId).replace('<intervalId>', intervalId),
      userErrorMessage: 'Fetching witness interval summary failed. Please try again.',
      data: {
        startdate: startDate,
        enddate: endDate,
        groupby: groupby
      },
      cancelKey: cancelKey
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  getWitnessDashboardConfigs: async function () {
    
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getWitnessDashboardConfigs',
      url: apiDetails.url + riwiqEndpoints.witnessDashboardConfigs,
      userErrorMessage: 'Fetching the Dashboard configurations for this user failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  putWitnessDashboardConfig: async function (dashboardId, dashboardConfigArray) {

    const cancelKey = 'putWitnessDashboardConfig' + dashboardId;

    const fullStatus = await apiConnector.requestEx({ 
      requestName: 'putWitnessDashboardConfig' + dashboardId,
      method: 'put',
      url: apiDetails.url + riwiqEndpoints.witnessDashboardConfig.replace('<dashboardId>', dashboardId),
      userErrorMessage: 'Updating the dashboard configuration failed. Please refresh the page and try again.',
      data: dashboardConfigArray,
      cancelKey: cancelKey
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },
    
  postWitnessDashboardConfig: async function (dashboardConfigArray) {
      
    const fullStatus = await apiConnector.requestEx({
      requestName: 'postWitnessDashboardConfig',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.witnessDashboardConfigs,
      userErrorMessage: 'Creating a new dashboard configuration failed. Please refresh the page and try again.',
      data: dashboardConfigArray
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },
    
  getWitnessIntervalDataFromId: async function (clinicId, intervalId, startDate, endDate, groupBy, cancelKey = undefined) {
    
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getWitnessIntervalDataFromId',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.witnessIntervalDataFromId.replace('<clinicId>', clinicId).replace('<intervalId>', intervalId),
      userErrorMessage: 'Fetching Witness Interval data for known Interval failed. Please try again.',
      data: {
        startdate: startDate,
        enddate: endDate,
        groupby: groupBy
      },
      cancelKey: cancelKey
    });
    
    let status = this.transformStatus(fullStatus);

    return status;
  },    
  
  getWitnessIntervalDataFromConfig: async function (clinicId, startPoints, endPoints, type, startDate, endDate, groupBy, cancelKey) {
    
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getWitnessIntervalDataFromConfig',
      method: 'post',
      url: apiDetails.url + riwiqEndpoints.witnessIntervalDataFromConfig.replace('<clinicId>', clinicId),
      userErrorMessage: 'Fetching Witness Interval data for active configuration failed. Please try again.',
      data: {
        startdate: startDate,
        enddate: endDate,
        startwitnesspoints: startPoints,
        endwitnesspoints: endPoints,
        intervaltype: type,
        groupby: groupBy
      },
      cancelKey: cancelKey
    });
    
    let status = this.transformStatus(fullStatus);

    return status;
  },

  getAvailableDatabases: async function () {
    const fullStatus = await apiConnector.requestEx({
      requestName: 'getAvailableDatabases',
      url: apiDetails.url + riwiqEndpoints.availableDatabases,
      userErrorMessage: 'Fetching the list of available databases failed. Please refresh the page and try again.'
    });

    let status = this.transformStatus(fullStatus);

    return status;
  },

  //
  // Helpers
  //
  
  transformStatus: function (status) {
    // let simpleStatus = {
    //   result: status.result,
    // };
   
    // if (status.result) {
    //   // When success send back the response
    //   simpleStatus.response = status.response;
    // }
    // else {
    //   // When failure send back a simple error object
    //   simpleStatus.error = {
    //     user: status.response.user,
    //     alertUser: status.response.alertUser,
    //     isCancel: status.response.isCancel
    //   }
    // }
    
    return status;
  }
  
};
