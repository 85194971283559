// src/plugins/fontAwesome.js
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas, far, fal);

const RIW_ICON_SET = {
  //Vuetify overrides
  menu: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'bars']
    }
  },

  // Menu icon definitions
  riwIconSet_menu_dashboard: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'desktop']
    }
  },
  riwIconSet_menu_witness: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'eye']
    }
  },
  riwIconSet_menu_admin: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'briefcase']
    }
  },
  riwIconSet_menu_globalAdmin: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'globe-americas']
    }
  },
  riwIconSet_menu_systemAdmin: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'wrench']
    }
  },
  riwIconSet_userMenu_refreshPermissions: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'recycle']
    }
  },
  riwIconSet_userMenu_refreshToken: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'redo-all']
    }
  },
  riwIconSet_userMenu_changePassword: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'pencil-alt']
    }
  },
  riwIconSet_userMenu_signIn: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'sign-in-alt']
    }
  },
  riwIconSet_userMenu_signOut: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'sign-out-alt']
    }
  },

  // General purpose icon definitions
  riwIconSet_edit: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'pencil']
    }
  },
  riwIconSet_delete: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'trash']
    }
  },
  riwIconSet_reload: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'redo']
    }
  },
  riwIconSet_back: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'chevron-left']
    }
  },
  riwIconSet_forward: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'chevron-right']
    }
  },
  riwIconSet_pdf: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'file-pdf']
    }
  },
  riwIconSet_csv: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'file-csv']
    }
  },
  riwIconSet_search: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'search']
    }
  },
  riwIconSet_user: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'user']
    }
  },
  riwIconSet_users: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'users']
    }
  },
  riwIconSet_device: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'server']
    }
  },
  riwIconSet_clinicConnector: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'network-wired']
    }
  },
  riwIconSet_expandDown: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'angle-down']
    }
  },
  riwIconSet_collapseUp: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'angle-up']
    }
  },
  riwIconSet_close: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'times']
    }
  },
  riwIconSet_jumpLeft: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'angle-left']
    }
  },
  riwIconSet_jumpRight: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'angle-right']
    }
  },
  riwIconSet_increased: {
    component: FontAwesomeIcon,
    props: {
      // icon: ['fas', 'long-arrow-alt-up']
      icon: ['fas', 'caret-up']
    }
  },
  riwIconSet_decreased: {
    component: FontAwesomeIcon,
    props: {
      // icon: ['fas', 'long-arrow-alt-down']
      icon: ['fas', 'caret-down']
    }
  },
  riwIconSet_addToDashboard: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'plus-circle']
    }
  },
  riwIconSet_done: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'check-circle']
    }
  },
  riwIconSet_info: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'info-circle']
    }
  },
  riwIconSet_connectionUp: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'check-circle']
    }
  },
  riwIconSet_connectionDown: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'minus-circle']
    }
  },
  riwIconSet_receivingData: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'sort-amount-down']
    }
  },
  riwIconSet_dragToRearrange: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'arrows-alt-v']
    }
  },
  riwIconSet_help: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fas', 'question-circle']
    }
  },
  riwIconSet_warning: {
    component: FontAwesomeIcon,
    props: {
      icon: ['fal', 'exclamation-triangle']
    }
  }

};

export { RIW_ICON_SET };
