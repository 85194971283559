// src/plugins/vuetify.js

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { RIW_ICON_SET } from '@/assets/icons/riw-icon-set';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#005CAB',
        secondary: '#00B9D2',
        accent: '#8B2CE1',
        success: '#2D9A43',
        error: '#E1134F',
        warning: '#F58025',
        info: '#00B9D2',
        amber: '#F58025',
        corporateBlue: '#005CAB',
        lightBlue: '#00B9D2'
      }
    }
  },
  icons: {
    iconfont: 'faSvg',
    values: RIW_ICON_SET
  }
});