import Vue from 'vue';
import Router from 'vue-router';
import store from '@/models/store/store';
import userAuthentication from '@/models/common/user-authentication';

const Permission = {
  None: 0,
  ClinicUser: 1,
  ClinicAdmin: 2,
  GlobalAdmin: 3,
  SysAdmin: 4
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'homeView',
      component: () => import('./components/HomeView'),
      meta: {
        title: 'Home View',
        requiredPermissionLevel: Permission.ClinicUser
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('./views/Login'),
          meta: {
            title: 'Login'
          }
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('./views/AboutPage'),
          meta: {
            title: 'Terms and Conditions',
            requiredPermissionLevel: Permission.ClinicUser,
            requiresClinic: false
          }
        },
        {
          path: 'witnessStatistics',
          name: 'witnessStatistics',
          component: () => import('./views/WitnessStatistics'),
          meta: {
            title: 'Statistics',
            requiredPermissionLevel: Permission.ClinicUser,
            requiresClinic: true
          }
        },
        {
          path: 'witnessEvents',
          name: 'witnessEvents',
          component: () => import('./views/WitnessEvents'),
          meta: {
            title: 'Witness Events',
            requiredPermissionLevel: Permission.ClinicUser,
            requiresClinic: true
          }
        },
        {
          path: 'witnessDashboard',
          name: 'witnessDashboard',
          component: () => import('./views/WitnessDashboard'),
          meta: {
            title: 'Dashboard',
            requiredPermissionLevel: Permission.ClinicUser,
            requiresClinic: true
          }
        },
        {
          path: 'witnessIntervals',
          name: 'witnessIntervals',
          component: () => import('./views/WitnessIntervals'),
          meta: {
            title: 'Witness Intervals',
            requiredPermissionLevel: Permission.ClinicUser,
            requiresClinic: true
          }
        },
        {
          path: 'adminUsers',
          name: 'adminUsers',
          component: () => import('./views/AdminUsers'),
          meta: {
            title: 'Manage Users',
            requiredPermissionLevel: Permission.ClinicAdmin,
            requiresClinic: true
          }
        },
        {
          path: 'adminDevices',
          name: 'adminDevices',
          component: () => import('./views/AdminDevices'),
          meta: {
            title: 'Manage Devices',
            requiredPermissionLevel: Permission.ClinicAdmin,
            requiresClinic: true
          }
        },
        {
          path: 'adminConnectors',
          name: 'adminConnectors',
          component: () => import('./views/AdminConnectors'),
          meta: {
            title: 'Manage Connectors',
            requiredPermissionLevel: Permission.ClinicAdmin,
            requiresClinic: true
          }
        },
        {
          path: 'globalAdminUsers',
          name: 'globalAdminUsers',
          component: () => import('./views/GlobalAdminUsers'),
          meta: {
            title: 'Manage Global Users',
            requiredPermissionLevel: Permission.GlobalAdmin,
            requiresClinic: true
          }
        },
        {
          path: 'globalAdminClinics',
          name: 'globalAdminClinics',
          component: () => import('./views/GlobalAdminClinics'),
          meta: {
            title: 'Manage Clinics',
            requiredPermissionLevel: Permission.GlobalAdmin,
            requiresClinic: true
          }
        }
      ]
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== from.name) {
    
    const requiredPermissionLevel = to.meta?.requiredPermissionLevel;

    if (requiredPermissionLevel === undefined || requiredPermissionLevel == Permission.None) {
      next();
      return;
    }

    var currentPermissionLevel = Permission.None;
    if (store.getters.isSysAdmin) { currentPermissionLevel = Permission.SysAdmin; }
    else if (store.getters.isGlobalAdmin) { currentPermissionLevel = Permission.GlobalAdmin; }
    else if (store.getters.isAdminOfAnyClinic) { currentPermissionLevel = Permission.ClinicAdmin; }
    else if (store.getters.idToken) { currentPermissionLevel = Permission.ClinicUser; }

    if (currentPermissionLevel == Permission.None) {
      next(false);
      userAuthentication.redirectUserToLogIn();
    } else if (currentPermissionLevel < requiredPermissionLevel) {
      next(false);
    } else {
      next();
    }
  }
});

export default router;
