export default {
  state: {
    errors: []
  },

  getters: {
    latestError (state) {
      return state.errors[state.errors.length - 1];
    }
  },

  mutations: {
    registerError (state, newError) {
      if (state.errors.length >= 20) {
        state.errors.shift();
      }
      state.errors.push(newError);
    }
  },

  actions: {
    registerError: async function ({ commit }, payload) {
      // Set up a default error object
      const tempError = {
        error: 'There is an error in the error registration algorithm. How ironic.',
        friendly: 'An unexpected error occurred while attempting to register an error.',
        user: 'An unexpected error occurred (E418).',
        timestamp: new Date(),
        alertUser: false,
        displayForSeconds: 5,
        blockUI: false,
        extra: undefined,
        logToLogRocket: true
      };
  
      // Override paratemers if they have been provided
      if ('error' in payload) {
        tempError.error = payload.error;
      }
      if ('friendly' in payload) {
        tempError.friendly = payload.friendly;
      }
      if ('user' in payload) {
        tempError.user = payload.user;
      }
      if ('timestamp' in payload) {
        tempError.timestamp = payload.timestamp;
      }
      if ('alertUser' in payload) {
        tempError.alertUser = payload.alertUser;
      }
      if ('displayForSeconds' in payload) {
        tempError.displayForSeconds = payload.displayForSeconds;
      }
      if ('blockUI' in payload) {
        tempError.blockUI = payload.blockUI;
      }
      if ('extra' in payload) {
        tempError.extra = payload.extra;
      }
      if ('logToLogRocket' in payload) {
        tempError.logToLogRocket = payload.logToLogRocket;
      }
  
      if (tempError.logToLogRocket) {
        // Push the error into LogRocket
        // LogRocket.captureMessage(tempError.friendly, { extra: tempError })
      }
  
      commit('registerError', tempError);
    }
  }
};