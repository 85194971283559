import { mapGetters } from 'vuex';
import smyError from '@/components/SmyError';
import userAuthentication from '@/models/common/user-authentication';

const actions = {
  navigateToWitnessStatistics:  0,
  navigateToWitnessDashboard:   1,
  navigateToWitnessEvents:      2,
  navigateToWitnessIntervals:   3,
  navigateToAdminUsers:         4,
  navigateToAdminConnectors:    5,
  navigateToAdminDevices:       6,
  navigateToGlobalAdminClinics: 7,
  navigateToGlobalAdminUsers:   8,
  navigateToSysAdminSandbox:    9,

  refreshPermissions:           10,
  refreshToken:                 11,
  changePassword:               12,
  signIn:                       13,
  signOut:                      14
};

export default {
  name: 'HomeView',
  actions: actions,
  components: {
    smyError
  },
  props: {
  },
  data() {
    return {
      userDrawer: null,
      selectedAPI: undefined,
      apiDataLoading: false,
      expandAll: true,
      views: ['login'],
      drawer: true,
      userImageUrl: require('@/assets/images/3.png'),
      allViews: {
        witnessDB: [
          'witness_statistics',
          'witness_dashboard',
          'witness_overview',
          'witness_intervals',
          'witness_events',
          'witness'
        ],
        admin: [
          'admin_users',
          'admin_connectors',
          'admin_devices',
          'admin'
        ],
        globalAdmin: [
          'global_admin_clinics',
          'global_admin_users',
          'global_admin'
        ],
        sysAdmin: [
          'sys_admin',
          'sandbox'
        ]
      },
      items: [
        { icon: '$riwIconSet_menu_dashboard', title: 'Dashboard', viewId: 'dashboard' },
        { icon: '$riwIconSet_userMenu_signIn', title: 'Retry login', action: actions.signIn, viewId: 'login'},
        {
          icon: '$riwIconSet_menu_witness',
          iconAlt: 'Witness',
          title: 'Witness',
          active: true,
          activeDefault: true,
          viewId: 'witness',
          items: [
            { title: 'Statistics', action: actions.navigateToWitnessStatistics, viewId: 'witness_statistics' },
            { title: 'Dashboard', action: actions.navigateToWitnessDashboard, viewId: 'witness_dashboard' },
            { title: 'Events', action: actions.navigateToWitnessEvents, viewId: 'witness_events' },
            { title: 'Intervals', action: actions.navigateToWitnessIntervals, viewId: 'witness_intervals' }
          ]
        },
        {
          icon: '$riwIconSet_menu_admin',
          iconAlt: 'Admin',
          title: 'Admin',
          active: false,
          viewId: 'admin',
          items: [
            { title: 'Users', action: actions.navigateToAdminUsers, viewId: 'admin_users' },
            { title: 'Connectors', action: actions.navigateToAdminConnectors, viewId: 'admin_connectors' },
            { title: 'Devices', action: actions.navigateToAdminDevices, viewId: 'admin_devices' }
          ]
        },
        {
          icon: '$riwIconSet_menu_globalAdmin',
          iconAlt: 'Global admin',
          title: 'Global Admin',
          active: false,
          viewId: 'global_admin',
          items: [
            { title: 'Clinics', action: actions.navigateToGlobalAdminClinics, viewId: 'global_admin_clinics' },
            { title: 'Admin Users', action: actions.navigateToGlobalAdminUsers, viewId: 'global_admin_users' }
          ]
        },
        {
          icon: '$riwIconSet_menu_systemAdmin',
          iconAlt: 'System admin',
          title: 'Sys Admin',
          active: false,
          viewId: 'sys_admin',
          items: [
            { title: 'Sandbox', action: actions.navigateToSysAdminSandbox, viewId: 'sandbox' }
          ]
        }
      ],
      userViews: {
        refreshPermissions: [
          'refresh_permissions'
        ],
        refreshTokens: [
          'refresh_token'
        ],
        signedOut: [
          'sign_in',
          'about'
        ],
        signedIn: [
          'change_password',
          'sign_out',
          'about'
        ]
      },
      allUserItems: [
        {
          icon: '$riwIconSet_userMenu_refreshPermissions',
          title: 'Refresh Permissions',
          action: actions.refreshPermissions,
          viewId: 'refresh_permissions',
          class: 'ml-2 mt-2'
        },
        {
          icon: '$riwIconSet_userMenu_refreshToken',
          title: 'Refresh Token',
          action: actions.refreshToken,
          viewId: 'refresh_token',
          class: 'ml-2 mt-2'
        },
        {
          icon: '$riwIconSet_userMenu_changePassword',
          title: 'Change Password',
          action: actions.changePassword,
          viewId: 'change_password',
          class: 'ml-2 mt-2'
        },
        {
          icon: '$riwIconSet_userMenu_signIn',
          title: 'Sign in',
          action: actions.signIn,
          viewId: 'sign_in',
          class: 'ml-2'
        },
        {
          icon: '$riwIconSet_userMenu_signOut',
          title: 'Sign out',
          action: actions.signOut,
          viewId: 'sign_out',
          class: 'ml-2'
        },
        {
          icon: '$riwIconSet_info',
          title: 'Terms and Conditions',
          action: actions.navigateToAbout,
          viewId: 'about',
          class: 'ml-2'
        }
      ],
      userItems: [ ]
    };
  },
  computed: {
    ...mapGetters({
      currentClinic: 'currentClinic',
      allClinics: 'allClinics',
      emailAddress: 'emailAddress',
      isAdminOfAnyClinic: 'isAdminOfAnyClinic',
      isSysAdmin: 'isSysAdmin',
      isGlobalAdmin: 'isGlobalAdmin',
      idToken: 'idToken'
    }),
    username: function () {
      return this.emailAddress ?? '';
    },
    pageTitle: function () {
      return this.$route.meta.title;
    }
  },
  methods: {
    refreshIdToken() {
      // Create hidden IFrame to request authorize endpoint
      const authorizeIFrame = document.createElement('iframe');
      
      let iframeURL = userAuthentication.getRefreshIdTokenUrl();
      
      authorizeIFrame.setAttribute('src', iframeURL);
      authorizeIFrame.setAttribute('id', 'tokenRefreshIFrame');
      authorizeIFrame.style.width = '20';
      authorizeIFrame.style.height = '20';
      authorizeIFrame.style.border = '2px solid red';
      authorizeIFrame.style.display = 'none';
      
      authorizeIFrame.onload = async () => {
        document.getElementById('tokenRefreshIFrame').remove();
      };
      
      document.body.appendChild(authorizeIFrame);      
      
    },
    handleNewIdToken(event) {
      if (event.type == 'RefreshIdToken')
      {
        let newIdToken = event.detail;
        
        this.$store.commit('updateIdToken', newIdToken);
      } 
      
    },
    collapse() {
      for (let item of this.items) {
        item.active = true;
        if ('active' in item && item.active === true && item.activeDefault != true) {
          item.active = false;
        }
      }
    },
    processAction(action) {
      switch (action) {
        case actions.navigateToWitnessStatistics:
          this.navigateToWitnessStatistics();
          break;
        case actions.navigateToWitnessDashboard:
          this.navigateToWitnessDashboard();
          break;
        case actions.navigateToWitnessEvents:
          this.navigateToWitnessEvents();
          break;
        case actions.navigateToWitnessIntervals:
          this.navigateToWitnessIntervals();
          break;

        case actions.navigateToAdminUsers:
          this.navigateToAdminUsers();
          break;
        case actions.navigateToAdminConnectors:
          this.navigateToAdminConnectors();
          break;
        case actions.navigateToAdminDevices:
          this.navigateToAdminDevices();
          break;

        case actions.navigateToGlobalAdminClinics:
          this.navigateToGlobalAdminClinics();
          break;
        case actions.navigateToGlobalAdminUsers:
          this.navigateToGlobalAdminUsers();
          break;

        case actions.navigateToSysAdminSandbox:
          break;
          
        case actions.refreshPermissions:
          this.refreshPermissions();
          break;
        case actions.refreshToken:
          break;
        case actions.changePassword:
          this.navigateToChangePassword();
          break;
        case actions.signIn:
          this.navigateToSignIn();
          break;
        case actions.signOut:
          this.navigateToSignOut();
          break;
        case actions.navigateToAbout:
          this.navigateToAbout();
          break;
      }
    },
    navigateToWitnessStatistics() {
      this.$router.push('witnessStatistics');
    },
    navigateToWitnessDashboard() {
      this.$router.push('witnessDashboard');
    },
    navigateToWitnessEvents(){
      this.$router.push('witnessEvents');
    },
    navigateToWitnessIntervals() {
      this.$router.push('witnessIntervals');
    },
    navigateToAdminUsers () {
      this.$router.push('adminUsers');
    },
    navigateToAdminConnectors () {
      this.$router.push('adminConnectors');
    },
    navigateToAdminDevices () {
      this.$router.push('adminDevices');
    },
    navigateToGlobalAdminClinics () {
      this.$router.push('globalAdminClinics');
    },
    navigateToGlobalAdminUsers () {
      this.$router.push('globalAdminUsers');
    },
    navigateToSignIn() {
      userAuthentication.redirectUserToLogIn();
    },
    navigateToSignOut() {
      userAuthentication.redirectUserToLogOut();
    },
    navigateToChangePassword() {
      userAuthentication.redirectUserToChangePassword();
    },
    navigateToAbout () {
      this.$router.push('about');
    },
    refreshPermissions() {
      this.refreshViews();
    },
    refreshViews: async function () {
      let views = new Set();

      if (!this.currentClinic) {
        views.add('login'); // Failover, so we don't have an empty menu blocking rendering
      } else {
        this.allViews.witnessDB.forEach(view => views.add(view));
      }
      
      if (this.isAdminOfAnyClinic) {
        this.allViews.admin.forEach((view) => views.add(view));
      }
      if (this.isSysAdmin || this.isGlobalAdmin) {
        if (this.allClinics?.length > 0) {
          this.allViews.admin.forEach((view) => views.add(view));
        }
        this.allViews.globalAdmin.forEach((view) => views.add(view));
      }
      if (this.isSysAdmin) {
        this.allViews.sysAdmin.forEach((view) => views.add(view));
      }
      this.views = Array.from(views);
      
      let userItems = new Set();
      
      if (this.emailAddress === undefined) {
        this.userViews.signedOut.forEach((view) => userItems.add(view));
      } else {
        if (this.isGlobalAdmin || this.isSysAdmin) {
          //this.userViews.refreshPermissions.forEach((view) => userItems.add(view));
        }
        if (this.isSysAdmin) {
          //this.userViews.refreshTokens.forEach((view) => userItems.add(view));
        }
        this.userViews.signedIn.forEach((view) => userItems.add(view));
      }
      this.userItems = Array.from(userItems);
    }
  },
  mounted() {
    this.refreshViews();
    
    // Start the Timer to refresh IdToken with 15m interval
    this.$store.commit('startIdTokenRefreshTimer');
    
    // And listen for a message from IFRAME
    window.document.addEventListener('RefreshIdToken', this.handleNewIdToken, false);
  },
  watch: {
    currentClinic: function (newVal, oldVal) {
      this.selectedClinic = this.currentClinic;
      this.refreshViews();
    },
    allClinics: function (newVal, oldVal) {
      this.refreshViews();
    }
  }
};