<template>
  <div>
    <v-overlay
      absolute
      z-index=8
      :value="(showingError && blockUI)"
    >
    </v-overlay>
    <v-snackbar
        v-model="showingError"
        color="error"
        :timeout="displayTime"
        top
      >
        {{ message }}
        <br />
        <v-btn
          dark
          text
          class="mt-2"
          @click="showingError = false"
        >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>

import smyErrorComponentModel from '@/component-models/smy-error-component-model';

export default smyErrorComponentModel;

</script>
