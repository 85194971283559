export default {

  state: {
    historicalChartData: undefined
  },

  getters: {
    historicalChartData: function (state) {
      return state.historicalChartData;
    }
  },

  mutations: {
    setHistoricalChartData: function (state, data) {
      state.historicalChartData = data;
    }
  }
};