import Vue from 'vue';
import Vuex from 'vuex';

import generalStore from '@/models/store/general-store';
import errorStore from '@/models/store/error-store';
import filterStore from '@/models/store/filter-store';
import historicalStore from '@/models/store/historical-store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    generalStore,
    errorStore,
    filterStore,
    historicalStore
  }
});
