import apiCache from '@/models/common/api-cache';
import axios from 'axios';
import generalFunctions from '@/models/common/general-functions';
import store from '@/models/store/store';
import userAuthentication from '@/models/common/user-authentication';

var cancelTokens = {};

function constructConfig(configPatch, method, url, params, data, cancelToken, nonce) {
  const baseConfig = {
    method: method,
    url: url,
    params: params,
    data: data,
    headers: {
      Authorization: 'Bearer ' + store.getters.idToken,
      'Content-Type': 'application/json'
    },
    nonce: nonce
  };

  if (cancelToken) {
    baseConfig['cancelToken'] = cancelToken.token;
  }

  if (configPatch) {
    for (const key in configPatch) {
      if (key in baseConfig) {
        // key already exists, so check if it is an object on both sides
        if (typeof configPatch[key] === 'object' && configPatch[key] !== null && typeof baseConfig[key] === 'object' && baseConfig[key] !== null) {
          // Merge the two objects using ES6 spread syntax
          baseConfig[key] = { ...baseConfig[key], ...configPatch[key] };
        } else {
          // Entry is not an object, so just overwrite
          baseConfig[key] = configPatch[key];
        }
      } else {
        baseConfig[key] = configPatch[key];
      }
    }
  }
    
  return baseConfig;
}

async function doCancel(cancelKey, nonce) {
  if (cancelKey in cancelTokens) {
    await cancelTokens[cancelKey][0].cancel('Request superseded');
  }

  const CancelToken = axios.CancelToken.source();
  cancelTokens[cancelKey] = [CancelToken, nonce];

  return CancelToken;
}

function removeCancelToken(cancelKey) {
  delete cancelTokens[cancelKey];
}

function generateNonce(salt = '') {
  return generalFunctions.cyrb64Hash(new Date().getTime().toString() + salt);
}

export default {

  //
  //  Returns status object { true, response } or { false, error } 
  //  + doesn't throw error in the bin (store)
  //
  requestEx: async function ({
    requestName = '<unnamed_request>', // Used for internal error generation
    method = 'get',
    url = undefined,
    params = {},
    userErrorMessage = 'An unexpected networking error occurred, please refresh the page and try again.',
    data = undefined,
    useCache = false,
    cacheSecondsTTL = 5,
    configPatch = undefined,
    cancelKey = undefined,
    rethrowErrors = false, // Rethrows all errors (but not cancels)
    dispatchErrors = true // Use the 'registerError' Vuex setter (to allow errors to be watched and displayed by smyError component). Does not dispatch cancels
  } = {}) {

    let requestStringified = url + generalFunctions.deterministicStringify(params);
    if (data) {
      requestStringified += generalFunctions.deterministicStringify(data);
    }

    const requestHash = generalFunctions.cyrb64Hash(requestStringified);
    
    if (useCache) {
      var cacheResponse = apiCache.checkCache(requestHash);
      if (cacheResponse != false) {
        return cacheResponse;
      }
    }

    const nonce = generateNonce(requestHash);

    let cancelToken = undefined;
    if (cancelKey) {
      cancelToken = await doCancel(cancelKey, nonce);
    }
    
    const finishedConfig = constructConfig(configPatch, method, url, params, data, cancelToken, nonce);
    
    const response = await axios.request(finishedConfig).then(axiosResponse => {
      var output = {
        statusCode: axiosResponse.status,
        config: axiosResponse.config,
        data: axiosResponse.data,
        fullResponse: axiosResponse,
        result: true
      };

      if (cancelKey && cancelTokens[cancelKey] && axiosResponse.config && axiosResponse.config.nonce && (cancelTokens[cancelKey][1] == axiosResponse.config.nonce)) {
        removeCancelToken(cancelKey);
        output['mostRecentWithCancelKey'] = true;
      } else if (cancelKey){
        output['mostRecentWithCancelKey'] = false;
      }

      return output;
    }).catch(error => {
      if(error?.response?.status === 401) {
        userAuthentication.redirectUserToLogIn();
      }

      let errorObj = {
        error: error,
        friendly: 'The request named: ' + requestName + ' generated an error.',
        user: userErrorMessage,
        alertUser: true,
        isCancel: false
      };

      let outObj = {
        result: false
      };

      if (axios.isCancel(error)) {
        errorObj.friendly = 'The request named: ' + requestName + ' has been superseded.';
        errorObj.user = '';
        errorObj.alertUser = false;
        errorObj.isCancel = true;
      } else if (error.response) {
        outObj['statusCode'] = error.response.status || undefined;
        outObj['config'] = error.response.config || undefined;
        outObj['data'] = error.response.data || undefined;
        outObj['fullResponse'] = error.response || undefined;
      }

      outObj['error'] = errorObj;

      if (dispatchErrors && !axios.isCancel(error)) {
        store.dispatch('registerError', errorObj);
      }

      if (rethrowErrors && !axios.isCancel(error)) {
        throw(error);
      }

      // remove from cache here if nonce matches

      // remove from cancel if nonce matches
      
      return outObj;
    });
    
    // Add to cache - this is wrong! need to add before resolution - see card 525 and the new algo spike
    if (useCache && response.result) {
      apiCache.storePromise(requestHash, response, cacheSecondsTTL);
    }

    return response;
  }
};
